<template>
	<div class="about">
		<TheAbout />
	</div>
</template>

<script>
// @ is an alias to /src
import TheAbout from "@/components/TheAbout.vue";

export default {
	name: "About",
	components: {
		TheAbout,
	},
};
</script>
